import { BackgroundCheckComponent } from "../../modules/reviewer/profile/general/background-check/background-check.component";
import { secureBackgroundStatus } from "./secureBackgroundStatus";

export const constants = {
    emailRegex: /^[a-zA-Z0-9._+-]+@[-+_.0-9A-Z_a-z]+\.[a-z.]{2,5}$/,
    zipCodeRegex: /^\b\d{5}(-\d{4})?\b$/,
    phoneNumberRegex: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    pocRegex: /^[a-zA-Z0-9]+[a-zA-Z0-9\.,#\-\s/]*$/,
    format: {
        date: {
            format: 'MMMM dd, yyyy',
            timeZone: 'UTC'
        }
    },
    pagination: {
        offers: {
            initialReviewers: 10,
            defaultReviewers: 10,
            initialOffset: 0
        }
    },
    talentPoolReviewerPaging: {
        initialReviewers:10,
        defaultReviewers: 10,
        initialOffset: 0
    },
    select: {
        averageRatingForDefaultSelection: 1.4,
        overtimeMultiplier: 1.5,
        defaultRateFixedTo: 2,
        minimumHeightToDisplayButtonBar: 0,
        minimumLengthForAutoRefresh: 25,
        rockerAction: {
            up: 'UP',
            down: 'DOWN'
        },
        success: {
            movedSuccessfully: 'Moved Successfully',
            relativityUser: 'Successfully created relativity User.',
        },
        error: {
            minimumHourlyRate: 'Hourly rate should be greater than 0.',
            minimumDesiredFreelancer: 'Desired freelancer(s) should be greater than filled freelancer(s).',
            minimumSearchCharacters: 'Minimum three characters are required for search.',
            noReviewerSelected: 'Please select the reviewers to move.',
            searching: 'Error searching reviewers',
            loadingReviewers: 'Error loading reviewers',
            updatingRole: 'Error updating role',
            reviewerCount: 'Error reviewers count',
            project: 'Error loading project',
            errorRelativityUser: 'Error creating relativity User.'
        },
        reviewerQueryType: {
            workTogether: 'workedTogetherReviewers',
            restricted: 'restrictedReviewers',
            occupied: 'occupied',
            declined: 'declined',
            conflicted: 'conflicted',
            search: '',
            firedQuit: 'firedQuit',
            talentPool: 'talentPool',
            all: ''
        }
    },
    roleStatus: {
        active: 'ACTIVE',
        archive: 'ARCHIVE',
        published: 'PUBLISHED',
        started: 'STARTED'
    },
    projectStatus: {
        created: 'CREATED',
        published: 'PUBLISHED',
        started: 'STARTED',
        closed: 'CLOSED',
        archived: 'ARCHIVED'
    },
    roleAction: {
        added: 'added',
        updated: 'updated',
        delete: 'delete',
        cancel: 'cancel'
    },
    positionStatus: {
        occupied: "OCCUPIED",
        quit: "QUIT"
    },
    presumptiveStatus: {
        APPROVED: "APPROVED",
        DENIED: "DENIED",
        ASSIGNED: "ASSIGNED"
    },
    offerStatus: {
        dispatched: "DISPATCHED",
        viewed: 'VIEWED',
        accepted: 'ACCEPTED',
        declined: 'DECLINED',
        unavailable: 'UNAVAILABLE'
    },
    userVerificationStatus: {
        pendingEmailVerification: 'PENDING_EMAIL_VERIFICATION',
        incomplete: 'INCOMPLETE',
        verified: "VERIFIED",
        pendingAdminVerification: "PENDING_ADMIN_VERIFICATION"
    },
    maxLength: {
        endMessage: 200,
        documentInstructions: 1000
    },
    offer: {
        statsUnit: 60000
    },
    stars: {
        default: 5,
        favorite: 5,
        restricted: 1,
        minRating: 3
    },
    timeSheet: {
        notesMaxLen: 200,
        timesheetOverlap: 'Timesheets overlap!',
        overlapMsg: 'Time overlaps with an already recorded time on current or past projects.',
        timesheetApprove: "You can't edit an approved or submitted timesheet",
        approveMsg: "You can't edit an approved or submitted timesheet.",
        timesheetClockIn: "Employee is already on the clock",
        clockInMsg: "You are already clocked in."
    },
    browsers: [
        {
            name: 'Chrome',
            url: 'https://www.google.com/chrome/browser/desktop/index.html',
            icon: '/assets/images/chrome.png'
        },
        {
            name: 'FireFox',
            url: 'https://www.mozilla.org/en-US/firefox/new/',
            icon: '/assets/images/firefox.png'
        },
        {
            name: 'Safari',
            url: 'https://safari.en.softonic.com/',
            icon: '/assets/images/safari.png'
        }
    ],
    emailType: {
        project: 'PROJECT',
        role: 'ROLE',
        individual: 'INDIVIDUAL'
    },
    defaultRoleStartTime: {
        hour: 9,
        minute: 0
    },
    daysDiffrenceBetweenDate: 14,
    firebaseSchemas: {
        discussions: '/discussions',
        threads: '/threads',
        userLastView: '/userLastView',
    },
    userDeleteReasons: [
        { value: 'USER_REQUESTED', label: 'User Requested' },
        { value: 'INACTIVITY', label: 'Inactivity' },
        { value: 'DISCIPLINARY', label: 'Disciplinary' }
    ],
    messageType: {
        project: 'PROJECT',
        role: 'ROLE',
        reviewer: 'REVIEWER'
    },
    secureBackgroundCheckType: {
        BAR_ADMISSION: 'BAR_ADMISSION'
    },
    nscTransactionStatus: { UCF: 'UCF' },
    accountRelativityType: {
        SIMPLE: 'SIMPLE',
        ADVANCED: 'ADVANCED'
    },
    toggleCriteria: {
        reapprovalList: 'reapprovalList',
        newlyAddedCriteria: 'newlyAddedCriteria',
        filterUsers: 'filterUsers',
        deletedReviewers: 'deletedReviewers',
        restrictedReviewers: 'globallyRestrictedReviewers',
        limitedPermission: 'limitedPermission',
        accountClosureRequest: 'closedAccounts'
    },
    userRoles: {
        firmManager: 'FIRM_MANAGER',
        reviewer: 'REVIEWER',
        admin: 'ADMIN',
        projectManager: 'PROJECT_MANAGER',
        projectOwner: 'PROJECT_OWNER'
    },
    firmUserRoles: [
        { value: 'FIRM_MANAGER', label: 'Firm Manager' },
        { value: 'PROJECT_OWNER', label: 'Project Manager' }
    ],
    messages: {
        MESSAGE_DELETED: 'This message has been deleted.'
    },
    documentUploadStatus: {
        UPLOADED: 'UPLOADED',
        NOT_UPLOADED: 'NOT_UPLOADED',
        PARTIALLY_UPLOADED: 'PARTIALLY_UPLOADED'
    },
    projectType: {
        LITIGATION_INVESTIGATION: 'Litigation/Investigation Document Review',
        CYBER: 'Cyber'
    },
    permissionType: {
        personalInfo: 'Are you sure? Turning off this permission you wont receive any new job updates.',
        education: 'Are you sure? Turning off this permission you wont receive any new job updates.',
        bar: 'Are you sure? Turning off this permission you wont receive any new job updates.',
        tsheet: 'Are you sure? Turning off this permission you wont receive any new job updates.',
        relativity: 'Are you sure? Turning off this permission you wont receive any new job updates.'
    },
    requestAccountDeleteHeading: 'Are you sure you want to remove your Staftr account?',
    requestAccountDeleteContent: 'This will remove your account data and privileges from Staftr within 48 hours',
    unsuspendAccount: 'Are you sure you want to Unsuspend the account?',
    sendBulkEmail: 'Are you sure you wan to send email to everyone?',
    conflictFileDialogKey: "doConflictCaseFilingLater",
    documentUploadSizeInMbs: 5,
    emailAttachmentsLimitInMbs: 25,
    mfaMethods: ['phone', 'qrCode'],
    notificationTypesSubject: {
        NEW_OFFER: 'New Project Offer Received',
        NEW_OFFER_REMINDER_24: 'Reminder: You Have a Project Offer Waiting',
        NEW_OFFER_REMINDER_48: 'Reminder: A Project Offer is Waiting For Your Response',
        PROFILE_MODIFICATION: 'Personal Information Modification',
        INVOICE_REMINDER: 'Reminder: Submit Your Invoice',
        NEW_MESSAGE: 'New Message Received',
        CREDENTIALS_EXPIRING_TODAY: 'Credentials Expiring Today',
        CREDENTIALS_EXPIRING_SOON: 'Credentials Expiring Soon'
    },
    profileStrengthValue: {
        contact: 15,
        education: 20,
        bar: 20,
        skill: 15,
        document: 15,
        experience: 10,
        notificationPreference: 5
    },
    staftrProfileStrengthValue: {
        credentials: 20,
        skill: 20,   
        document: 20,
    },
    staftrProfileStrengthPublicValue: {
        contact: 15,
        education: 20,
        notificationPreference: 5
    },
    debounceValue: 500,
    capitalized: function (string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    defaultDocuSignFieldsCoordinates: {
        signHere: {
            top:300,
            left:210
        },
        textTab_fullName: {
            top:300,
            left:220
        },
        textTab_email:{
            top:300,
            left:230
        },
        textTab_custom:{
            top:300,
            left:250
        },
        checkBoxTab:{
            top:300,
            left:290
        },
        dateSigned:{
            top:300,
            left:310
        },
        initialHere:{
            top:300,
            left:180
        },
        radioTab:{
            top:250,
            left:150
        },
        signerAttachment:{
            top:350,
            left:150
        }
    },
    features:{
        AUTH_ZERO:  'auth0',
        DYNAMIC_ROLE_CRITERIA:  'dynamicRoleCriteria',
        DYNAMIC_CREDENTIAL:  'dynamicCredential',
        MULTI_TENANT: 'multiTenant',
        SEARCH_MARKET: 'searchMarket',
        JOB_TYPE: 'jobType',
        REVIEWER_AUTO_APPROVAL: 'reviewerAutoApproval'
    },
    defaultValues: {
        INVITE_USER_PASSWORD: 'Tester@1234'
    },
    publicRoutes: {
        urls: ['auth/login', 'auth/refreshToken']
    },
    timezones: ['US/Eastern', 'US/Central', 'US/Mountain', 'US/Pacific'],
    sortingOrder: {
        descending: 'DESC',
        ascending: 'ASC'
    },
    sortingFilter : [
        { name: ' Activity (recently logged in)', value: 'lastLogin' },
        { name: 'Date Verified', value: 'dateVerified' },
        { name: 'Star Rating', value: 'averageRating' },
        { name: 'None', value: 'DESC' },
    ],
    dynamicCriteria: {
        title: 'Manage Skills and Experience',
        breadcrumbs: {
            settings: 'Settings',
            dynamicCriteria: 'Skills and Experience',
            create: 'Create',
            edit: 'Edit'
        },
        buttonText: {
            create: 'CREATE',
            edit: 'EDIT',
            save: 'SAVE',
        },
        placeholderText: {
            globalCriteria: 'Choose Staftr Templates',
            jobType: 'Job Type'
        }
    },
    dynamicCredential: {
        title: 'Manage Credentialing',
        breadcrumbs: {
            settings: 'Settings',
            dynamicCredential: 'Credentialing',
            create: 'Create',
            edit: 'Edit'
        },
        buttonText: {
            create: 'CREATE',
            edit: 'EDIT',
            save: 'SAVE',
        },
        placeholderText: {
            jobType: 'Job Type'
        },
        titleText: {
            add: 'Add',
            edit: 'Edit'
        },
    },
    secureBackgroundStatus: {
        completed: 'COMPLETED',
        meetsRequirement: 'Meets Requirements',
        pending: 'PENDING',
        needsFurtherReview:'Needs Further Review',
        doesntMeetsRequirement:"Doesn't Meet Requirements",
        doesNotMeetsRequirement:"Does Not Meet Requirements",
        manuallyRefused:"MANUAL_REFUSED",
        manualApproved: 'MANUAL_APPROVAL',
        nscApproval: 'NSC_APPROVAL',
        na:'N/A',
        NSC_PENDING:'NSC Verification Failed',
        NSC_UCF: 'NSC Results (UCF)'
    },
    states: [
        {name: 'Alabama', code: 'AL'},
        {name: 'Alaska', code: 'AK'},
        {name: 'Arizona', code: 'AZ'},
        {name: 'Arkansas', code: 'AR'},
        {name: 'California', code: 'CA'},
        {name: 'Colorado', code: 'CO'},
        {name: 'Connecticut', code: 'CT'},
        {name: 'Delaware', code: 'DE'},
        {name: 'District Of Columbia', code: 'DC'},
        {name: 'Federated States Of Micronesia', code: 'FM'},
        {name: 'Florida', code: 'FL'},
        {name: 'Georgia', code: 'GA'},
        {name: 'Guam', code: 'GU'},
        {name: 'Hawaii', code: 'HI'},
        {name: 'Idaho', code: 'ID'},
        {name: 'Illinois', code: 'IL'},
        {name: 'Indiana', code: 'IN'},
        {name: 'Iowa', code: 'IA'},
        {name: 'Kansas', code: 'KS'},
        {name: 'Kentucky', code: 'KY'},
        {name: 'Louisiana', code: 'LA'},
        {name: 'Maine', code: 'ME'},
        {name: 'Marshall Islands', code: 'MH'},
        {name: 'Maryland', code: 'MD'},
        {name: 'Massachusetts', code: 'MA'},
        {name: 'Michigan', code: 'MI'},
        {name: 'Minnesota', code: 'MN'},
        {name: 'Mississippi', code: 'MS'},
        {name: 'Missouri', code: 'MO'},
        {name: 'Montana', code: 'MT'},
        {name: 'Nebraska', code: 'NE'},
        {name: 'Nevada', code: 'NV'},
        {name: 'New Hampshire', code: 'NH'},
        {name: 'New Jersey', code: 'NJ'},
        {name: 'New Mexico', code: 'NM'},
        {name: 'New York', code: 'NY'},
        {name: 'North Carolina', code: 'NC'},
        {name: 'North Dakota', code: 'ND'},
        {name: 'Northern Mariana Islands', code: 'MP'},
        {name: 'Ohio', code: 'OH'},
        {name: 'Oklahoma', code: 'OK'},
        {name: 'Oregon', code: 'OR'},
        {name: 'Palau', code: 'PW'},
        {name: 'Pennsylvania', code: 'PA'},
        {name: 'Puerto Rico', code: 'PR'},
        {name: 'Rhode Island', code: 'RI'},
        {name: 'South Carolina', code: 'SC'},
        {name: 'South Dakota', code: 'SD'},
        {name: 'Tennessee', code: 'TN'},
        {name: 'Texas', code: 'TX'},
        {name: 'Utah', code: 'UT'},
        {name: 'Vermont', code: 'VT'},
        {name: 'Virgin Islands', code: 'VI'},
        {name: 'Virginia', code: 'VA'},
        {name: 'Washington', code: 'WA'},
        {name: 'West Virginia', code: 'WV'},
        {name: 'Wisconsin', code: 'WI'},
        {name: 'Wyoming', code: 'WY'}
    ],

    addReviewerCredentials: {
        breadcrumbs: {
            settings: 'Settings',
            addCredentials: 'Add Credential',
            create: 'Create',
            edit: 'Edit'
        },
    },
    steps: {
        contact: 'contactInformation',
        backgroundCheck: 'backgroundCheck',
        education: 'education'
    },
    citizenshipType: [
        {
            name: 'US Citizen',value:'US Citizen'
        },
        {
            name: 'Non-US Citizen',value:'Non-US Citizen'
        }
    ],
    documentTypes: {
        resume: 'RESUME',
        certification:'CERTIFICATION',
        additionalDocument:'ADDITIONAL_DOCUMENT',
        credentials_certificate:'CREDENTIALS_CERTIFICATE'

    },
    url:{
        pastProject: 'pastProjects'
    },
    onboarding: 'onboarding',
    unknown_error: {
        status_code: 0,
        message: 'Oops! Something went wrong. Please try again later.',
    },
    internalServerError: {
        defaultErrorMessage: 'Internal Server Error'
    }
}
export  const UserRoles = {
    firmManager: 'FIRM_MANAGER',
    reviewer: 'REVIEWER',
    admin: 'ADMIN',
    projectManager: 'PROJECT_MANAGER',
    projectOwner: 'PROJECT_OWNER'
}
